.center__text {
  
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 80px;
  color: hwb(255 98% 1% / 0.993);
  font-weight: bold;
  transform: translate(-50%, -50%);
  }
  
  .paragraph_text{
    position: relative;
    font-size: 22px;
    color: hwb(245 0% 85% / 0.993);
  }
  
    .font-caption
    {
      font-size: 50px;
      color: hwb(245 1% 77% / 0.993);
      font-weight: bold;
    }

    .font-semicaption
    {
      font-size: 25px;
      color: hwb(245 1% 77% / 0.993);
      font-weight: bold;
    }

    .font-cursol-bottom
    {
      font-size: 50px;
      color: hwb(0 100% 0% / 0.993);
      font-weight: bold;
    }
    .d-block
    {
      filter: blur(6px);
    -webkit-filter: blur(4px);
    background-image: linear-gradient(rgb(228, 228, 233), rgb(242, 242, 248));
    background-blend-mode: color;
    }
    .bg-text {
      background-color: rgb(0,0,0); /* Fallback color */
      background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
      color: white;
      font-weight: bold;
      border: 3px solid #f1f1f1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 80%;
      padding: 20px;
      text-align: center;
    }
    .btn-cursol
    {
  
      position: absolute;
      top: 68%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 20%;
      padding: 20px;
      text-align: center;
  
    }