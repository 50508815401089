ul.b {
    list-style-type: square;
    font-size: 20px;
  }
  .font-main
  {
    font-size: 21px;
    font-weight: normal;
  }

  .app {
    display: flex;
    padding: 5rem;
    justify-content: space-evenly;
    font-family: Verdana, Geneva, Tahoma, sans-serif
  }
  
  .map-container {
    height: 500px;
    width: 50%;
    border: 2px solid black;
    border-radius: 20px;
  }