.carousel-caption-New {
  top: 350px;
  bottom: auto;   
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
  .font-cursol
  {
    font-size: 75px;
    color: hwb(255 98% 1% / 0.993);
    font-weight: bold;
  }
  .font-cursol-bottom
  {
    font-size: 50px;
    color: hwb(0 100% 0% / 0.993);
    font-weight: bold;
  }
  .d-block
  {
    filter: blur(6px);
  -webkit-filter: blur(4px);
  background-image: linear-gradient(rgb(230, 230, 236), rgb(237, 237, 241));
  background-blend-mode: color;
  }
  .bg-text {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    border: 3px solid #f1f1f1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
  }
  .btn-cursol
  {

    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 20%;
    padding: 20px;
    text-align: center;

  }